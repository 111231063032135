import React from 'react';
import { Link } from 'react-router-dom';

const Homepage = ({ inspections }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Unit</th>
          <th>Name</th>
          <th>Contact</th>
        </tr>
      </thead>
      <tbody>
        {inspections.map((inspection) => (
          <tr key={inspection.id}>
            <td style={{ textAlign: 'center' }}>
              <Link to={`/details/${inspection.id}`}>{new Date(inspection.rentEscalationDate).toLocaleDateString()}</Link>
            </td>
            <td style={{ textAlign: 'center' }}>
              <Link to={`/details/${inspection.id}`}>{inspection.unitNo}</Link>
            </td>
            <td style={{ textAlign: 'center' }}>
              <Link to={`/details/${inspection.id}`}>{inspection.tenantName}</Link>
            </td>
            <td style={{ textAlign: 'center' }}>
              <Link to={`/details/${inspection.id}`}>{inspection.tenantMobileNo}</Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Homepage;
