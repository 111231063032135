// Navbar.js
import React from 'react';

const Navbar = () => {
  return (
    <nav style={{ backgroundColor: '#221E5A', padding: '10px', color: 'white' }}>
      Bitprop
    </nav>
  );
};

export default Navbar;
