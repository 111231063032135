import React from 'react';
import { useParams, Link } from 'react-router-dom';

const InspectionDetails = ({ inspections }) => {
  const { inspectionId } = useParams();
  const inspection = inspections.find((ins) => ins.id === parseInt(inspectionId));

  if (!inspection) {
    return <p>Inspection not found</p>;
  }

  // Process the maintenance quotes URLs
  const maintenanceQuotesUrls = inspection.submitted_maintenance_quotes_url
    .split(',')
    .filter(url => url.trim() !== '') // Remove any empty strings from the array
    .map((url, index) => (
      // Create a clickable link for each URL
      <div key={index}>
        <a href={url.trim()} target="_blank" rel="noopener noreferrer">Maintenance Quote {index + 1}</a>
      </div>
    ));

  return (
    <div>
      <h2>Inspection Details</h2>
      {/* Buttons for Inspection Form and Maintenance Quote Form */}
      <div style={{ marginBottom: '20px' }}>
        <Link to="/" style={{ marginRight: '10px' }}><button>Back</button></Link>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <a href={inspection.prefilled_inspection_form} style={{ marginRight: '10px' }}><button>Inspection Form</button></a>
        <a href={inspection.prefilled_maintenance_quotes_form}><button>Maintenance Quote Form</button></a>
      </div>
      {/* Inspection Details Table */}
      <table>
        <tbody>
          <tr>
            <td>ID:</td>
            <td>{inspection.id}</td>
          </tr>
          <tr>
            <td>Lease ID:</td>
            <td>{inspection.lease_id}</td>
          </tr>
          <tr>
            <td>Date Notice Given:</td>
            <td>{inspection.escalationDate}</td>
          </tr>
          <tr>
            <td>Completed:</td>
            <td>{inspection.completed ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td>Tenant Name:</td>
            <td>{inspection.tenantName}</td>
          </tr>
          <tr>
            <td>Tenant Mobile No:</td>
            <td>{inspection.tenantMobileNo}</td>
          </tr>
          <tr>
            <td>Rent Escalation Date:</td>
            <td>{inspection.rentEscalationDate}</td>
          </tr>
          <tr>
            <td>Unit No:</td>
            <td>{inspection.unitNo}</td>
          </tr>
          <tr>
            <td>Street:</td>
            <td>{inspection.street}</td>
          </tr>
          <tr>
            <td>Suburb:</td>
            <td>{inspection.suburb}</td>
          </tr>
          <tr>
            <td><a href={inspection.submitted_inspection_form_url} target="_blank" rel="noopener noreferrer">Completed Inspection Form</a></td>
          </tr>
          <tr>
            <td>View submitted maintenance quotes:</td>
            <td>{maintenanceQuotesUrls.length > 0 ? maintenanceQuotesUrls : 'No Maintenance Quotes Submitted'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InspectionDetails;
